import dayjs from 'dayjs';
export default () => {
  const day = (): string => dayjs().format('HH:mm:ssA');
  const currentTime: globalThis.Ref<string> = ref(day());
  const updateCurrentTime = () => {
    currentTime.value = day();
  };
  const updateTimeInterval: NodeJS.Timeout = setInterval(
    updateCurrentTime,
    1000,
  );
  onBeforeUnmount(() => {
    clearInterval(updateTimeInterval);
  });
  return currentTime;
};
